import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable-submit-on-direct-upload"
export default class extends Controller {
  connect() {
    this.element.addEventListener("direct-upload:start", () => this.disableSubmit());
  }

  disableSubmit() {
    this.element.querySelector("button[type='submit']").disabled = true;
  }
}
