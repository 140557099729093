import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="help"
export default class extends Controller {
  connect() {}

  open(event) {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message");
      event.preventDefault();
      return false;
    } else {
      // trigger the default action, usually a mailto: link
      return true;
    }
  }
}
