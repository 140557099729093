import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="preview-file-upload"
export default class extends Controller {
  static targets = ["preview", "input", "loader"];

  connect() {
    this.form = this.element.closest("form");
    this.form.addEventListener("direct-upload:start", () => this.onStart());
    this.form.addEventListener("direct-upload:progress", (e) => this.onProgress(e));
    this.form.addEventListener("direct-upload:end", () => this.onEnd());
  }

  updatePreview(event) {
    const file = event.target.files[0];
    this.previewTarget.src = URL.createObjectURL(file);
  }

  onStart() {
    const ghost = this.previewTarget.cloneNode(true);
    ghost.classList.add("ghost");
    ghost.style.clipPath = "inset(0 0 100% 0)";
    const loadContainer = this.loaderTarget;
    loadContainer.appendChild(ghost);

    this.previewTarget.classList.add("opacity-20");
  }

  onProgress(event) {
    const progress = event.detail.progress;
    const ghost = this.loaderTarget.querySelector(".ghost");

    ghost.style.clipPath = `inset(${100 - progress}% 0 0 0)`;
  }

  onEnd() {
    this.previewTarget.classList.remove("opacity-20");
    const ghost = this.loaderTarget.querySelector(".ghost");
    ghost.remove();
  }
}
