import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ["menu"];

  connect() {}

  open() {
    console.log("open");
    this.menuTarget.classList.remove("hidden");
  }

  close() {
    this.menuTarget.classList.add("hidden");
  }
}
