import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="onboarding-checklist"
export default class extends Controller {
  static targets = ["continueButton", "checklistTypeRadio", "previewContainer", "magicForm"];

  connect() {
    this.checklistTypeRadioTargets.forEach((radio) => {
      radio.addEventListener("change", () => {
        // if magic, then show the form for that
        if (radio.value === "magic") {
          this.continueButtonTarget.classList.add("hidden");
          this.previewContainerTarget.classList.add("hidden");
          this.magicFormTarget.classList.remove("hidden");
        } else {
          this.continueButtonTarget.classList.remove("hidden");
          this.previewContainerTarget.classList.remove("hidden");
          this.magicFormTarget.classList.add("hidden");

          this.previewContainerTarget.querySelectorAll(`[data-preview-type]`).forEach((preview) => {
            preview.classList.add("hidden");
          });
          this.previewContainerTarget.querySelector(`[data-preview-type="${radio.value}"]`).classList.remove("hidden");
        }
      });
    });
  }

  togglePreview() {
    this.previewContainerTarget.classList.toggle("expanded");
  }
}
